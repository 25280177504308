import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
    var makeSameHeight = {
        init: function(){
            if ( $('.benefits__item').length > 0 ) {
                $('.benefits__title').matchHeight( );
            }    

            if ( $('.services__item').length > 0 ) {
                $('.services__title').matchHeight( );
            }                  
        }
    };

    makeSameHeight.init();

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            makeSameHeight.init();
        }, 500, 'same-height');
    });

})(jQuery);